const APIConstants = {
  // BASE_URL: "http://localhost:8000/api/v1/",
  LOCAL_URL: "http://localhost:3000/",
  // BASE_URL: "http://185.203.217.19/api/v1/",
  // BASE_URL: "http://192.168.1.77:8000/api/v1/",
  // BASE_URL: "http://192.168.1.77:8000/api/v1/",
  // BASE_URL: "https://www.cloudinstaller.app/api/v1",
  BASE_URL: "https://api.cloudinstaller.app/v1/",
  login: "login/",
  fileUpload: "ota/",
  signUp: "signup/",
  users: "users/",
  passwordreset: "password-rest/",
  contactUs: "contactus/",
  listuserapp: "users/files/",
  deleteuserapp: "ota/",
  forgot: "forgot/",
  resetPass: "reset/",
  download: "",
  DOWNLOAD_BASE_URL: "https://assets.cloudinstaller.app/",
  VERIFY: "https://api.cloudinstaller.app/v1/cf-token/",
};
export default APIConstants;
