import React from 'react'
import '../assets/styles/animation.css'; // Ensure to import the CSS file for styling
const AnimatedWave = () => {
  return (
    <>
             <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
    </>
  )
}

export default AnimatedWave