import { React, useState } from "react";
import Container from "react-bootstrap/Container";
import UserNavbar from "../components/UserNavbar";
import Footer from "../components/Footer";
import { IoPersonOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { UserContactUsRequest } from "../api/AuthReducer";
import { AppLogger } from "../utils/AppLogger";
import { unwrapResult } from "@reduxjs/toolkit";
import ContactSuccessModal from "../components/ContactSuccessModal";
import TurnstileWidget from "../components/TurnstileWidget";
import toast from "react-hot-toast";
import { useRef } from "react";

const ContactUs = ({ NAVIGATIONLINKS }) => {
  const turnstileRef = useRef();
  const [formDisable, setFormDisable] = useState(true);
  const dispatch = useDispatch();
  const [isValidatedToken, setValidatedToken] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [contactUsObj, setContactUsObj] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleTurnstileExpire = () => {
    // turnstileRef.current?.reset()
    toast.error("Token Expired - Please verify again!");
    setFormDisable(true);
  };

  function handleFormSubmission(e) {
    e.preventDefault();
    dispatch(UserContactUsRequest({ body: contactUsObj }))
      .then(unwrapResult)
      .then((response) => {
        if (response.data) {
          AppLogger("Contact us Response data === ", response.data);
        }
        setContactUsObj({
          name: "",
          email: "",
          message: "",
        });
        setModalShow(true);
      })
      .catch((error) => {
        AppLogger("Contact us Error === ", error);
        if (Array.isArray(error.email)) {
          toast.error(
            error.email.map((err) => {
              return err;
            })
          );
        }
      });
  }

  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container className="mb-10 terms-align-main mt-10 ">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center descriptionMain fs-1 contact-us-main-colour">
                Contact Us
              </h2>
              <p className="text-secondary mb-5 text-center">
                The best way to contact us is to use our contact form below.
                Please fill out all of the required fields and we will get back
                to you as soon as possible.
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>
        <div className="contact-us-main">
          <form className="formContact" onSubmit={handleFormSubmission}>
            <label for="name" className="form-label">
              Name: <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text">
                <IoPersonOutline />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="enter your name"
                id="name"
                maxLength={50}
                name="name"
                value={contactUsObj.name}
                onChange={(e) =>
                  setContactUsObj({ ...contactUsObj, name: e.target.value })
                }
                required
              />
            </div>
            <label for="email" className="form-label">
              Email: <span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <span className="input-group-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
              </span>
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="enter your email"
                name="email"
                value={contactUsObj.email}
                onChange={(e) =>
                  setContactUsObj({ ...contactUsObj, email: e.target.value })
                }
                required
              />
            </div>

            <div className="col-12">
              <label for="message" className="form-label">
                Message <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                placeholder="type your message here"
                id="message"
                name="message"
                rows="3"
                maxLength={500}
                value={contactUsObj.message}
                onChange={(e) =>
                  setContactUsObj({ ...contactUsObj, message: e.target.value })
                }
                required
              ></textarea>
            </div>
            <div
              className="w-100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className=" ">
                <TurnstileWidget
                  setValidatedFlareToken={setValidatedToken}
                  turnstileRef={turnstileRef}
                  handleExpiry={handleTurnstileExpire}
                  setFormDisable={setFormDisable}
                />
              </div>

              <div className="mb-5 mt-3 ">
                <input
                  disabled={formDisable === true}
                  type="submit"
                  name="submit"
                  className="mx-auto customButton  btn bg-primary-light-blue font-family-opensans fw-bold  hover-btn  text-light"
                  value={"Send"}
                />
              </div>
            </div>
          </form>
        </div>
      </Container>
      <Footer />
      <ContactSuccessModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ContactUs;
