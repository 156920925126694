import { React, useState } from "react";
import { Container } from "react-bootstrap";
import Description from "../components/Description";
import { Form } from "react-bootstrap";
import { ResetPasswordRequest } from "../api/AuthReducer";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { AppLogger } from "../utils/AppLogger";
import ChangePassModal from "../components/ChangePassModal";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import AppRoutingPaths from "../utils/AppRoutingPaths";
import InputGroup from "react-bootstrap/InputGroup";
import { Eye, EyeSlashFill, Key } from "react-bootstrap-icons";
const ChangePass = () => {
  const [modalShow, setModalShow] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const [resData, setResData] = useState(false);

  let id = queryParameters.get("id");
  const navigationTo = useNavigate();
  const [userBody, setUserBody] = useState({
    new_password: "",
    confirm_password: "",
    token: id,
  });
  const [isError, setError] = useState(null);
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const clickHandlerConfirm = () => {
    setShowConfirm((prev) => !prev);
  };
  function handleFormSubmission(e) {
    e.preventDefault();
    if (userBody.new_password === userBody.confirm_password) {
      setError(null);
      dispatch(ResetPasswordRequest({ body: userBody }))
        .then(unwrapResult)
        .then((response) => {
          if (response.data) {
            AppLogger(response.data);
            setResData("success");
          }
          setUserBody({
            new_password: "",
            confirm_password: "",
            token: "",
          });

          setModalShow(true);
        })
        .catch((error) => {
          AppLogger("Reset error  ==", error);
          console.log(error.token);
          error.token === undefined
            ? setResData(error.message)
            : setResData("Not Authenticated");

          setModalShow(true);
        });
    } else {
      setError("Password's don't match");
    }
  }
  return (
    <>
      <Container>
        <div className="d-flex height-100 align-items-center  justify-content-center">
          <div className="bg-light shadow p-5 mb-5 bg-body rounded reset-container border  border-1 ">
            <Description
              content="Change Password"
              classNames="descriptionMain font-family-poppins display-6 mb-2 text-center pb-2"
            />
            <Form className="" onSubmit={handleFormSubmission}>
              <Form.Label
                for="p"
                className="descriptionSubtitle font-family-opensans fs-6 text-dark "
              >
                Password:
              </Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="password-input">
                  <Key />
                </InputGroup.Text>
                <Form.Control
                  id="p"
                  type={showPass ? "text" : "password"}
                  placeholder="Password"
                  aria-label="password"
                  maxLength={60}
                  aria-describedby="password-input"
                  name="password"
                  onChange={(e) => {
                    setUserBody({
                      ...userBody,
                      new_password: e.target.value,
                    });
                  }}
                  required
                />
                <InputGroup.Text onClick={clickHandler}>
                  {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
                  {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
                  {showPass ? <Eye /> : <EyeSlashFill />}
                </InputGroup.Text>
              </InputGroup>

              <Form.Label
                for="cf"
                className="descriptionSubtitle font-family-opensans fs-6 text-dark "
              >
                Confirm Password:
              </Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="password-input">
                  <Key />
                </InputGroup.Text>
                <Form.Control
                  id="cf"
                  type={showConfirm ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  aria-label="password"
                  maxLength={60}
                  aria-describedby="password-input"
                  onChange={(e) => {
                    setUserBody({
                      ...userBody,
                      confirm_password: e.target.value,
                    });
                  }}
                  required
                />
                <InputGroup.Text onClick={clickHandlerConfirm}>
                  {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
                  {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
                  {showConfirm ? <Eye /> : <EyeSlashFill />}
                </InputGroup.Text>
              </InputGroup>

              <div className=" flex-column justify-content-center mb-4">
                <input
                  type="submit"
                  name="submit"
                  className="w-100 customButton rounded-pill bg-primary-light-blue font-family-opensans fw-bold mx-auto mt-2 hover-btn  text-light"
                  value="Change Password"
                />
              </div>
              {isError !== null && (
                <div className="text-center text-danger fs-6">{isError}</div>
              )}
            </Form>
          </div>
        </div>
      </Container>
      <ChangePassModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigationTo(AppRoutingPaths.login);
        }}
        data={resData}
      />
    </>
  );
};

export default ChangePass;
