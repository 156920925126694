import React from "react";
import UserNavbar from "../components/UserNavbar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import {
  AllPoliciesHeading,
  AllPoliciesParaOne,
  AllPoliciesParaOneData,
  AllPoliciesParaRemaining,
} from "../utils/AppConstants";
function PrivacyPolicies({ NAVIGATIONLINKS }) {
  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container className="mt-6 mb-10 terms-align-main">
        <div>
          <h1 className="descriptionMain fs-1  font-family-poppings fw-bold text-capitalized mt-5">
            {AllPoliciesHeading.title}
          </h1>
        </div>
        <div className="mb-5 mt-4 font-family-poppings">
          {AllPoliciesHeading.des}
        </div>
        <div className="mb-4 font-family-poppings">
          <h4>{AllPoliciesParaOne.title}</h4>
        </div>
        <div className=""> {AllPoliciesParaOne.des}</div>

        <div className="">
          <ul className="ulicon ">
            {AllPoliciesParaOneData.map((term, index) => (
              <li className="liicon liicon-before font-family-poppings">{term}</li>
            ))}
          </ul>
        </div>
        <>
          {AllPoliciesParaRemaining.map((term, index) => (
            <div>
              <h4 className="mb-4 font-family-poppings">{term.Title}</h4>
              <p className=" font-family-poppings mt-4">{term.Description}</p>
            </div>
          ))}
        </>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPolicies;
