import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthReducer from "../api/AuthReducer";
import logger from "redux-logger";
import { thunk } from "redux-thunk";

const ConfigureStore = () => {
  const rootReducer = combineReducers({
    AuthReducer: AuthReducer,
  });

  const middlewares = [thunk];
  if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
  }

  const persistConfig = {
    key: "root",
    storage,
  };
  const persistRdcers = persistReducer(persistConfig, rootReducer);

  const store = createStore(persistRdcers, applyMiddleware(...middlewares));

  const persistor = persistStore(store);

  return { persistor, store };
};

export default ConfigureStore;
