import React from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import ReactQrCode from "@devmehq/react-qr-code";
import APIConstants from "../utils/APIConstants";
import toast from "react-hot-toast";

import CopyToClipboard from "react-copy-to-clipboard";
const FilePublicAccess = ({
  isHide = true,
  data,
  handleNewFileUploadClick,
}) => {
  const [emails, setEmails] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [focused, setFocused] = React.useState(false);
  const constructMailtoLink = (x) => {
    const recipientString = emails.join(",");
    const encodedSubject = encodeURIComponent("App Link");
    const encodedBody = encodeURIComponent(x);
    const encodedBody2 = encodeURIComponent(message);
    return `mailto:${recipientString}?subject=${encodedSubject}&body=${encodedBody2}%0D%0A${encodedBody}`;
  };

  const handleSendEmails = (x) => {
    const mailtoLink = constructMailtoLink(x);
    window.location.href = mailtoLink;
  };
  return (
    <div>
      <div className="file-outer-container">
        <div className="file-upload-container d-flex justify-content-between flex-column respheight ">
          <div className="d-flex flew-row justify-content-center align-items-center">
            <div className="text-center fw-medium font-family-opensans fs-4 ">
              Your App is Ready: &nbsp;
            </div>
            <div className="text-decoration-none processBlockSubtitle text-center fw-bold font-family-opensans fs-4 ">
              <a
                className="cursor-pointer text-primary-light-blue"
                download
                href={
                  data.type === "apk"
                    ? APIConstants.DOWNLOAD_BASE_URL +
                      APIConstants.download +
                      data.type +
                      "/" +
                      data.token +
                      "/"
                    : "itms-services://?action=download-manifest&url=" +
                      APIConstants.DOWNLOAD_BASE_URL +
                      APIConstants.download +
                      data.type +
                      "/" +
                      data.token +
                      "/"
                }
              >
                Install
              </a>
            </div>
          </div>
          <div>
            <CopyToClipboard
              text={
                "https://cloudinstaller.app/" +
                APIConstants.download +
                data.type +
                "/" +
                data.token +
                "/"
              }
            >
              {
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toast.success("Copied");
                  }}
                  className=" btn  bg-primary-light-blue font-family-opensans fw-bold  hover-btn  text-light"
                >
                  Copy Link
                </button>
              }
            </CopyToClipboard>
          </div>
          <div className="text-decoration-none processBlockSubtitle text-center fw-bold font-family-opensans fs-6 d-flex align-items-center justify-content-center draganddrop">
            <div className="w-50 d-flex align-items-center justify-content-center h-100">
              <div
                className="d-flex align-items-center justify-content-center widthvw"
                style={{ height: "90%", width: "50%" }}
              >
                <ReactQrCode
                  value={
                    data.type === "apk"
                      ? APIConstants.DOWNLOAD_BASE_URL +
                        APIConstants.download +
                        data.type +
                        "/" +
                        data.token +
                        "/"
                      : "itms-services://?action=download-manifest&url=" +
                        APIConstants.DOWNLOAD_BASE_URL +
                        APIConstants.download +
                        data.type +
                        "/" +
                        data.token +
                        "/"
                  }
                />
              </div>
            </div>
            <div className="w-50 d-flex flex-column align-items-center justify-content-center draganddrop2">
              <div className="w-100 d-flex flex-column align-items-center justify-content-center draganddrop2">
                <form
                  className="formContact mt-4 draganddrop2"
                  onSubmit={""}
                  style={{ width: "80%" }}
                >
                  <div className="fs-5">Enter Recipients:</div>
                  <label for="email" className="form-label">
                    Email: <span className="text-danger">*</span>
                  </label>
                  <ReactMultiEmail
                    style={{
                      width: "100%",
                      overflowX: "scroll",
                      maxHeight: "150px",
                    }}
                    placeholder="Input your email"
                    id="email"
                    emails={emails}
                    onChange={(_emails) => {
                      setEmails(_emails);
                    }}
                    autoFocus={true}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                  <div className="col-12">
                    <label
                      for="message"
                      className="form-label d-flex align-items-center"
                    >
                      Message <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="type your message here"
                      id="message"
                      name="message"
                      rows="3"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      maxLength={500}
                      style={{ maxHeight: "200px" }}
                      required
                    ></textarea>
                  </div>

                  <div className="mb-5 mt-3  w-100 d-flex align-items-center justify-content-center">
                    <input
                      className="hover-btn bg-primary-light-blue btn mx-auto rounded  text-light"
                      type="submit"
                      name="submit"
                      onClick={() =>
                        handleSendEmails(
                          data.type === "apk"
                            ? APIConstants.DOWNLOAD_BASE_URL +
                                APIConstants.download +
                                data.type +
                                "/" +
                                data.token +
                                "/"
                            : "itms-services://?action=download-manifest&url=" +
                                APIConstants.DOWNLOAD_BASE_URL +
                                APIConstants.download +
                                data.type +
                                "/" +
                                data.token +
                                "/"
                        )
                      }
                      value={"Send"}
                      disabled={message && emails ? false : true}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="text-decoration-none processBlockSubtitle text-center fw-bold font-family-opensans fs-6">
            <button
              className="btn btn-outline-primary mt-2"
              onClick={handleNewFileUploadClick}
            >
              Upload a new file
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilePublicAccess;
