import { Turnstile } from "@marsidev/react-turnstile";
import { CLOUDFLAREKEYS } from "../utils/AppConstants";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { ValidateCloudFlareTokenRequest } from "../api/AuthReducer";

// import Cookies from 'js-cookie';
import { AppLogger } from "../utils/AppLogger";

const TurnstileWidget = ({
  setValidatedFlareToken,
  turnstileRef,
  handleExpiry,
  setFormDisable,
}) => {
  const dispatch = useDispatch();
  // const secret = CLOUDFLAREKEYS.CLOUD_FLARE_SECRET_KEY_1;

  const handleSuccess = async (token) => {
    try {
      const result = await dispatch(
        ValidateCloudFlareTokenRequest({ token: token })
      );
      const res = unwrapResult(result);
      AppLogger("=== Token Response ===", res);
      setValidatedFlareToken(res.data.success);
      if (res.data.success) {
        toast.success("Successfully Verified!");
        setFormDisable(false);
      } else {
        toast.error("Token validation failed");
        // process.env.NODE_ENV == "development" ? setFormDisable(false) : setFormDisable(true)
        setFormDisable(true);
      }
    } catch (err) {
      AppLogger("=== Token Error ===", err);
      setValidatedFlareToken(false);

      // process.env.NODE_ENV == "development" ? setFormDisable(false) : setFormDisable(true)
      setFormDisable(true);

      toast.error("Token validation failed");
    }
  };

  const handleTurnstileError = (error) => {
    // AppLogger('=== Turnstile Error ===', error);
    toast.error("An error occurred with Cloudflare Turnstile");
    setFormDisable(true);
    // process.env.NODE_ENV == "development" ? setFormDisable(false) : setFormDisable(true)

  };

  return (
    <div className="mx-auto my-2 text-center">
      <Turnstile
        ref={turnstileRef}
        siteKey={CLOUDFLAREKEYS.CLOUD_FLARE_SITE_KEY_1}
        onSuccess={handleSuccess}
        onError={handleTurnstileError}
        onExpire={handleExpiry}
        options={{ theme: "light", refreshExpired: "manual" }}
      />
    </div>
  );
};

export default TurnstileWidget;
