import React from "react";
import { Dots } from "react-activity";
export default function Spinner(props) {
  return (
    <div
      className="spinnerContainer"
      style={{ backgroundColor: "rgb(255, 255, 255, 0.85)" }}
    >
      <Dots type="dots" className="text-primary-light-blue" size={props.size} />
    </div>
  );
}
