import React, { useEffect } from "react";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import "./assets/styles/App.css";
import Features from "./pages/Features";
import { Register } from "./pages/Register";
import Faq from "./pages/Faq";
import ChangePass from "./pages/ChangePass";
import { NAVIGATIONLINKS } from "./utils/AppConstants";
import ContactUs from "./pages/ContactUs";
import NoMatch from "./components/NoMatch";
import Reset from "./pages/Reset";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ConfigureStore from "./store/Store";
import { Toaster } from "react-hot-toast";
import Dashboard from "./pages/Dashboard";
import Terms from "./pages/Terms";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import RestrictedLoginRoute from "./routes/RestrictedLoginRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./pages/Login";
import { stopLoaderAndEmptyErrors } from "./api/AuthReducer";
import AppDownload from "./pages/AppDownload";
function App() {
  const { store, persistor } = ConfigureStore();

  useEffect(() => {
    store.dispatch(stopLoaderAndEmptyErrors());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Toaster />
        <Routes>
          <Route
            path={"/"}
            element={<Home NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          ></Route>
          <Route
            path="/features"
            element={<Features NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          ></Route>

          <Route path="/confirm-password" element={<ChangePass />}></Route>
          <Route element={<RestrictedLoginRoute />}>
            <Route
              path="/register"
              element={<Register NAVIGATIONLINKS={NAVIGATIONLINKS} />}
            />
            <Route path="/login" element={<Login />}></Route>
          </Route>
          <Route
            path="/faq"
            element={<Faq NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          ></Route>
          <Route
            path="/contact-us"
            element={<ContactUs NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          ></Route>
          <Route path="*" element={<NoMatch />}></Route>
          <Route element={<PrivateRoute />}>
            <Route
              path="/dashboard"
              element={<Dashboard NAVIGATIONLINKS={NAVIGATIONLINKS} />}
            />
          </Route>
          <Route
            path="/terms"
            element={<Terms NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicies NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          />
          <Route
            path="/reset"
            element={<Reset NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          />
          <Route
            path="/change-password"
            element={<ChangePass NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          />
          <Route
            path={"/ipa/:id"}
            element={<AppDownload NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          />
          <Route
            path={"/apk/:id"}
            element={<AppDownload NAVIGATIONLINKS={NAVIGATIONLINKS} />}
          />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
