import axios from "axios";
import ApiConstants from "../utils/APIConstants";
import APIConstants from "../utils/APIConstants";
import { AppLogger } from "../utils/AppLogger";

// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
// axios.defaults.xsrfCookieName = "XCSRF-TOKEN"

const axiosRequest = axios.create({
  baseURL: ApiConstants.BASE_URL,
  // withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Connection: "keep-alive",
    // "Cache-Control": "no-cache",
    // Pragma: "no-cache",
    // Expires: "0",
    "Access-Control-Allow-Origin": "*",
  },
});

function apiLoginRequest(payload) {
  return axiosRequest.post(ApiConstants.login, payload.body);
}
function apiFileUploadRequest(payload) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("type", "ipa");
  return axiosRequest.post(ApiConstants.fileUpload, formData, config);
}

function apiGetFileListRequest() {
  return axiosRequest.get(ApiConstants.fileUpload);
}

function apiGetUserAnalyticsRequest(payload) {
  const config = {
    headers: { Authorization: `Token ${payload.token}` },
  };
  return axiosRequest.get(ApiConstants.home, config);
}
function apiUserSignupRequest(payload) {
  return axiosRequest.post(APIConstants.signUp, payload.body);
}
function apiUpdateUsernameRequest(payload) {
  AppLogger("API ===", payload);
  const path = ApiConstants.users + `${payload.parameter}/`;
  return axiosRequest.put(path, payload.body);
}
function apiContactUsRequest(payload) {
  return axiosRequest.post(APIConstants.contactUs, payload.body);
}
function apiListUsersAppRequest(payload) {
  const config = {
    headers: {
      Authorization: "Token " + payload.token,
    },
  };
  let path = ApiConstants.listuserapp + payload.id + "/";
  return axiosRequest.get(path, config);
}
function apiDeleteUsersAppRequest(payload) {
  let path = ApiConstants.deleteuserapp + payload.id + "/";
  return axiosRequest.delete(path);
}
function apiResetEmailRequest(payload) {
  let path = ApiConstants.forgot;
  return axiosRequest.post(path, payload.body);
}
function apiResetPasswordRequest(payload) {
  let path = ApiConstants.resetPass;
  return axiosRequest.post(path, payload.body);
}

function apiValidateCloudFlareTokenRequest(payload) {
  const formData = new FormData();
  formData.append("cf_token", payload.token);

  let path = APIConstants.VERIFY;
  const config = {
    headers: {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      // Host: "challenges.cloudflare.com",
      // // "Access-Control-Request-Methods": "OPTION,POST",
    },
  };

  return axios.post(path, formData, config);
}

export const AuthApiServices = {
  apiResetPasswordRequest,
  apiResetEmailRequest,
  apiDeleteUsersAppRequest,
  apiListUsersAppRequest,
  apiGetUserAnalyticsRequest,
  apiLoginRequest,
  apiFileUploadRequest,
  apiGetFileListRequest,
  apiUserSignupRequest,
  apiUpdateUsernameRequest,
  apiContactUsRequest,
  apiValidateCloudFlareTokenRequest,
};
