import React from "react";
import { Card } from "react-bootstrap";

import Description from "./Description";
const TestimonailCard = ({ classNames, testimonail, authorName, pic }) => {
  return (
    <Card
      className="mb-2 mx-auto testimonail-container col-md-4 text-center"
      style={{ width: "22rem" }}
    >
      <Card.Body>
        <Card.Img
          variant="top"
          className="mx-auto rounded-circle img-fluid testimonailImage"
          src={pic}
          alt="Clients_image"
        />
        <Card.Text
          style={{ height: "80px", maxHeight: "100px" }}
          className="mt-2"
        >
          <Description
            content={testimonail}
            classNames="fs-6 font-family-opensans text-center"
          />
        </Card.Text>
        <Card.Footer className="bg-transparent">
          <Description
            content={authorName}
            classNames="descriptionMain  font-family-poppins fw-bold fs-6 mt-3"
          />
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default TestimonailCard;
