import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthApiServices } from "./AuthApiServices";
import { AppLogger } from "../utils/AppLogger";

export const GetUserAnalyticsRequest = createAsyncThunk(
  "AuthReducer/GetUserAnalyticsRequest",
  async (payload) => {
    const response = await AuthApiServices.apiGetUserAnalyticsRequest(payload);
    return response.data;
  }
);

export const FileUploadRequest = createAsyncThunk(
  "AuthReducer/FileUploadRequest",
  async (payload) => {
    const response = await AuthApiServices.apiFileUploadRequest(payload);
    return response.data;
  }
);
export const FileListRequest = createAsyncThunk(
  "AuthReducer/FileListRequest",
  async () => {
    const response = await AuthApiServices.apiGetFileListRequest();
    return response;
  }
);
export const LogoutRequest = createAsyncThunk(
  "AuthReducer/LogoutRequest",
  async (payload) => {
    return payload;
  }
);

export const stopLoaderAndEmptyErrors = createAsyncThunk(
  "AuthReducer/stopLoaderAndEmptyErrors",
  async (payload) => {
    return payload;
  }
);

export const UserSignUpRequest = createAsyncThunk(
  "AuthReducer/UserSignUpRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiUserSignupRequest(payload);
      return response.data; // Return the entire response object
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const UserContactUsRequest = createAsyncThunk(
  "AuthReducer/UserContactUsRequest",
  async (payload, { rejectWithValue }) => {
    AppLogger("UseContactUsRequest Payloadd === ", payload);
    try {
      const response = await AuthApiServices.apiContactUsRequest(payload);
      return response.data; // Return the entire response object
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const UserLoginRequest = createAsyncThunk(
  "AuthReducer/UserLoginRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiLoginRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const UserPasswordResetRequest = createAsyncThunk(
  "AuthReducer/UserPasswordRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApiServices.apiResetPasswordRequest(payload);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const UpdateUsernameRequest = createAsyncThunk(
  "AuthReducer/UpdateUsernameRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await AuthApiServices.apiUpdateUsernameRequest(payload);

      return resp;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const ListUsersAppRequest = createAsyncThunk(
  "AuthReducer/ListUsersAppRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await AuthApiServices.apiListUsersAppRequest(payload);
      return resp;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const DeleteUsersAppRequest = createAsyncThunk(
  "AuthReducer/DeleteUsersAppRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await AuthApiServices.apiDeleteUsersAppRequest(payload);
      return resp;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Validate Cloud Flare Token
export const ValidateCloudFlareTokenRequest = createAsyncThunk(
  "AuthReducer/ValidateCloudFlareTokenRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await AuthApiServices.apiValidateCloudFlareTokenRequest(
        payload
      );
      return resp;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  }
);
export const ResetEmailRequest = createAsyncThunk(
  "AuthReducer/ResetEmailRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await AuthApiServices.apiResetEmailRequest(payload);
      return resp;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const ResetPasswordRequest = createAsyncThunk(
  "AuthReducer/ResetPasswordRequest",
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await AuthApiServices.apiResetPasswordRequest(payload);
      return resp;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  token: null,
  user: null,
  loading: "idle",
  error: null,
  passwordresetToken: null,
  userListApps: [],
};

const states = {
  pending: "pending",
  idle: "idle",
};

const AuthReducer = createSlice({
  name: "AuthReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // UserLoginRequest
    builder.addCase(UserLoginRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
      state.user = null;
    });
    builder.addCase(UserLoginRequest.fulfilled, (state, action) => {
      state.user = action.payload.data.data.user;
      state.token = action.payload.data.data.token;
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(UserLoginRequest.rejected, (state, action) => {
      state.loading = states.idle;
      state.user = null;
      state.error = null;
    });

    // get user
    builder.addCase(GetUserAnalyticsRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(GetUserAnalyticsRequest.fulfilled, (state, action) => {
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(GetUserAnalyticsRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    // FileUploadRequest
    builder.addCase(FileUploadRequest.pending, (state, action) => {
      // state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(FileUploadRequest.fulfilled, (state, action) => {
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(FileUploadRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    // FilGetRequest
    builder.addCase(FileListRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(FileListRequest.fulfilled, (state, action) => {
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(FileListRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    // User SignUp Request
    builder.addCase(UserSignUpRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(UserSignUpRequest.fulfilled, (state, action) => {
      state.user = action.payload.data.user;
      state.token = action.payload.data.token;
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(UserSignUpRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    //Username Update Request
    builder.addCase(UpdateUsernameRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(UpdateUsernameRequest.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(UpdateUsernameRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    // UserLogout Requset
    builder.addCase(LogoutRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(stopLoaderAndEmptyErrors.fulfilled, (state, action) => {
      state.loading = states.idle;
      state.error = null;
    });
    builder.addCase(LogoutRequest.fulfilled, (state, action) => {
      return {
        ...initialState,
      };
    });

    //UserPasswordResetRequest
    builder.addCase(UserPasswordResetRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(UserPasswordResetRequest.fulfilled, (state, action) => {
      state.passwordresetToken = action.payload.data;
      state.loading = states.fulfilled;
      state.error = null;
    });
    builder.addCase(UserPasswordResetRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    //UserContactUsRequest
    builder.addCase(UserContactUsRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(UserContactUsRequest.fulfilled, (state, action) => {
      state.loading = states.fulfilled;
      state.error = null;
    });
    builder.addCase(UserContactUsRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    //ListUsersAppRequest
    builder.addCase(ListUsersAppRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(ListUsersAppRequest.fulfilled, (state, action) => {
      state.userListApps = action.payload.data.data.reverse();
      state.loading = states.fulfilled;
      state.error = null;
    });
    builder.addCase(ListUsersAppRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    //DeleteUsersAppRequest
    builder.addCase(DeleteUsersAppRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(DeleteUsersAppRequest.fulfilled, (state, action) => {
      state.loading = states.fulfilled;
      state.error = null;
      state.userListApps = state.userListApps.filter(
        (item) => item.id != action.meta.arg.id
      );
    });
    builder.addCase(DeleteUsersAppRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });
    //ResetEmailRequest
    builder.addCase(ResetEmailRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(ResetEmailRequest.fulfilled, (state, action) => {
      state.loading = states.fulfilled;
      state.error = null;
    });
    builder.addCase(ResetEmailRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    //ResetPasswordRequest
    builder.addCase(ResetPasswordRequest.pending, (state, action) => {
      state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(ResetPasswordRequest.fulfilled, (state, action) => {
      state.loading = states.fulfilled;
      state.error = null;
    });
    builder.addCase(ResetPasswordRequest.rejected, (state, action) => {
      state.loading = states.idle;
    });

    //ValidateCloudFlareTokenRequest
    builder.addCase(ValidateCloudFlareTokenRequest.pending, (state, action) => {
      // state.loading = states.pending;
      state.error = null;
    });
    builder.addCase(
      ValidateCloudFlareTokenRequest.fulfilled,
      (state, action) => {
        state.loading = states.fulfilled;
        state.error = null;
      }
    );
    builder.addCase(
      ValidateCloudFlareTokenRequest.rejected,
      (state, action) => {
        state.loading = states.idle;
      }
    );
  },
});

export default AuthReducer.reducer;
