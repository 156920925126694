const AppRoutingPaths = {
  home: "/",
  features: "/features",
  register: "/register",
  faq: "/faq",
  pageNotFound: "*",
  reset: "/reset",
  terms: "/terms",
  privacyPolicy: "/privacy-policy",
  contactUs: "/contact-us",
  plans: "/plans",
  dashboard: "/dashboard",
  login : "/login"
};
export default AppRoutingPaths;
