import React from "react";
// import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppLogger } from "../utils/AppLogger";
import { Navigate, Outlet } from "react-router-dom";
const RestrictedLoginRoute = () => {
  const { token } = useSelector((state) => state.AuthReducer);
  AppLogger("User Token ==== ", token);
  let authToken = token ? false : true;
  AppLogger("Token Status===", authToken);
  return authToken == false ? <Navigate to="/" /> : <Outlet />;
};

export default RestrictedLoginRoute;
