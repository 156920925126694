import React, { useState, useEffect } from "react";
import { Container, Row, Stack } from "react-bootstrap";
import { AppLogger } from "../utils/AppLogger";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Description from "../components/Description";
import UserNavbar from "../components/UserNavbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import toast from "react-hot-toast";
import { CgProfile } from "react-icons/cg";
import {
  UpdateUsernameRequest,
  ListUsersAppRequest,
  DeleteUsersAppRequest,
} from "../api/AuthReducer";
import AvatarEditor from "react-avatar-editor";
import APIConstants from "../utils/APIConstants";
import { IoLogoAndroid, IoLogoApple } from "react-icons/io";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { PaginationControl } from "react-bootstrap-pagination-control";

const Dashboard = ({ NAVIGATIONLINKS }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  const { user, token, userListApps } = useSelector(
    (state) => state.AuthReducer
  );

  const [isPubInSelected, setPubInpSelected] = useState(false);
  const endOffset = page * limit;
  const itemOffset = page * limit - limit;
  const uarray = userListApps.filter((single) => single.token !== null);
  const currentItems = uarray.slice(itemOffset, endOffset);
  const [showSaveCancelCTAs, setCancelSaveCTAs] = useState(false);
  var usernameRegex = /^[a-zA-Z]+([._]?[a-zA-Z0-9]+)*$/;
  useEffect(() => {
    dispatchListUsersApp();
  }, []);

  const [pubInp, setPubInp] = useState({
    username: user.username,
  });

  const currentUsername = user.username;

  const handleOnChange = (value) => {
    setPubInp({ ...pubInp, username: value });

    if (value == currentUsername) {
      setCancelSaveCTAs(false);
    } else {
      setCancelSaveCTAs(true);
    }
  };

  function changeUserName(e) {
    const usernew = {
      username: pubInp["username"],
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    };
    if (usernameRegex.test(usernew.username)) {
      setPubInp({ ...pubInp, username: usernew.username });
      dispatchUpdateusernameAction(usernew);
    } else {
      toast.error("Invalid user name");
    }
  }
  function dispatchUpdateusernameAction(editedUser) {
    dispatch(UpdateUsernameRequest({ parameter: user.id, body: editedUser }))
      .then(unwrapResult)
      .then((resp) => {
        AppLogger("API Response ===", resp);
        toast.success("Updated");
      })
      .catch((error) => {
        AppLogger("API Error===", error);
        toast.error(error.username[0]);
      });
  }
  function dispatchListUsersApp() {
    dispatch(ListUsersAppRequest({ id: user.id, token: token }))
      .then(unwrapResult)
      .then((resp) => {
        AppLogger("API Response ===", resp);
      })
      .catch((error) => {
        AppLogger("API Error===", error);
      });
  }
  function dispatchDeleteUsersApp(x) {
    dispatch(DeleteUsersAppRequest({ id: x }))
      .then(unwrapResult)
      .then((resp) => {
        AppLogger("API Response ===", resp);
      })
      .catch((error) => {
        AppLogger("API Error===", error);
      });
  }

  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mt-10"
        >
          <Tab eventKey="home" title="Profile">
            <section id="#profile" className="d-flex flex-column flex-fill">
              <Row className="my-2">
                <div className="d-flex gap-3 align-items-center"></div>
              </Row>
              <Row>
                <div style={{ width: "100vw", height: "80vh" }}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "80%" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      className="d-flex align-items-center flex-column justify-content-evenly"
                    >
                      <div
                        className="d-flex align-items-center justify-content-center "
                        style={{
                          height: "60%",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            height: "200px",
                            width: "200px",
                            borderRadius: "100%",

                            zIndex: 99999,
                          }}
                          className="d-flex align-items-center justify-content-center "
                        >
                          <CgProfile
                            style={{
                              height: "100%",
                              width: "100%",
                              backgroundColor: "White",
                            }}
                            className="hammad"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          height: "50vh",

                          marginTop: "-15vh",
                        }}
                        className="d-flex align-items-center justify-content-center width40vw"
                      >
                        <table className="table widthres80">
                          <tbody>
                            <tr className="w-100">
                              <td className="fw-bold font-family-opensans">
                                Email
                              </td>
                              <td colspan="2">
                                <Description
                                  content={user.email}
                                  classNames=" fw-bold font-family-opensans  d-flex align-items-center "
                                />
                              </td>
                            </tr>

                            <tr>
                              <td className="fw-bold font-family-opensans">
                                Name
                              </td>
                              <td>
                                <div
                                  className="d-flex flex-fill align-items-center justify-content-center"
                                  style={{
                                    border: "1px solid",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    // value="Siliconplex"
                                    placeholder="Siliconplex"
                                    maxLength={50}
                                    value={pubInp["username"]}
                                    className={`${
                                      isPubInSelected
                                        ? "bg-file-upload-color"
                                        : "bg-transparent"
                                    } form-control  border-0 `}
                                    onFocus={(e) => {
                                      setPubInpSelected(true);
                                    }}
                                    onBlur={() => {
                                      setPubInpSelected(false);
                                    }}
                                    onChange={(e) => {
                                      handleOnChange(e.target.value);
                                    }}
                                  />
                                </div>
                              </td>

                              <td>
                                {showSaveCancelCTAs == true ? (
                                  <>
                                    <div className="d-flex gap-1">
                                      <button
                                        onClick={(e) => {
                                          changeUserName(e);
                                          setCancelSaveCTAs(false);
                                        }}
                                        className=" btn  bg-primary-light-blue font-family-opensans fw-bold mx-auto  hover-btn  text-light"
                                      >
                                        Update
                                      </button>
                                      <button
                                        className=" btn  bg-file-upload-color font-family-opensans fw-bold mx-auto  hover-btn"
                                        onClick={(e) => {
                                          setPubInp({
                                            ...pubInp,
                                            username: currentUsername,
                                          });
                                          setCancelSaveCTAs(false);
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={(e) => {
                                        setPubInpSelected(true);
                                      }}
                                      className=" btn  bg-primary-light-blue font-family-opensans fw-bold mx-auto  hover-btn  text-light"
                                    >
                                      Update
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td className="fw-bold font-family-opensans">
                                Plan
                              </td>
                              <td colspan="2">
                                <Description
                                  content="Free Plan"
                                  classNames=" fw-bold font-family-opensans text-light-color  d-flex align-items-center"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </section>
          </Tab>
          <Tab eventKey="profile" title="Apps">
            <section id="#myappd">
              <Row className="my-2">
                <div className="d-flex gap-3 align-items-center"></div>
              </Row>
              <Row>
                <div className="table-responsive-sm containcss">
                  <table
                    className="table table-borderless  align-middle  "
                    style={{ minWidth: "900px" }}
                  >
                    <thead>
                      <tr className=" border-bottom">
                        <td className="fw-bold font-family-opensans">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"Logo"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>
                        <td className="fw-bold font-family-opensans">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"App Name"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>
                        <td className="fw-bold font-family-opensans">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"App Build"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>
                        <td className="fw-bold font-family-opensans">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"App Identifier"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>
                        <td className="fw-bold font-family-opensans">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"Link"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>
                        <td className="fw-bold font-family-opensans">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"Expiry Date"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>

                        <td className=" text-end ">
                          <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                            <Description
                              content={"Actions"}
                              classNames=" fw-bold font-family-opensans "
                            />
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {uarray.length !== 0 ? (
                        currentItems.map((data, index) => {
                          return (
                            <tr className=" border-bottom  " key={index}>
                              <td className="fw-bold font-family-opensans">
                                <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                                  {data.type === "apk" ? (
                                    <IoLogoAndroid className="text-success fs-3" />
                                  ) : (
                                    <IoLogoApple className="fs-3" />
                                  )}
                                </div>
                              </td>
                              <td className="fw-bold font-family-opensans">
                                <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                                  <Description
                                    content={data?.bundle_name}
                                    classNames=" fw-bold font-family-opensans "
                                  />
                                </div>
                              </td>
                              <td className="fw-bold font-family-opensans">
                                <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                                  <Description
                                    content={data?.version_code}
                                    classNames=" fw-bold font-family-opensans "
                                  />
                                </div>
                              </td>
                              <td className="fw-bold font-family-opensans">
                                <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                                  <Description
                                    content={data?.bundle_identifier}
                                    classNames=" fw-bold font-family-opensans "
                                  />
                                </div>
                              </td>
                              <td className="fw-bold font-family-opensans">
                                <div className="d-flex align-items-center flex-grow-1 gap-5 justify-content-center">
                                  <button
                                    className="hover-btn btn border border-primary text-primary"
                                    onClick={() => {
                                      moment(data.valid_until).isAfter(
                                        moment()
                                      ) ? (
                                        <></>
                                      ) : (
                                        toast.error("Link expired ")
                                      );
                                    }}
                                  >
                                    {moment(data.valid_until).isAfter(
                                      moment()
                                    ) ? (
                                      <a
                                        href={
                                          data.type === "apk"
                                            ? APIConstants.DOWNLOAD_BASE_URL +
                                              APIConstants.download +
                                              data.type +
                                              "/" +
                                              data.token +
                                              "/"
                                            : "itms-services://?action=download-manifest&url=" +
                                              APIConstants.DOWNLOAD_BASE_URL +
                                              APIConstants.download +
                                              data.type +
                                              "/" +
                                              data.token +
                                              "/"
                                        }
                                        className="  "
                                      >
                                        Install
                                      </a>
                                    ) : (
                                      "Link Expired"
                                    )}
                                  </button>
                                </div>
                              </td>
                              <td className="fw-bold font-family-opensans">
                                <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-5 ">
                                  {/* {data.valid_until.split("T")[0]} */}
                                  {moment(
                                    moment.utc(data.valid_until).toDate()
                                  ).format("YYYY-MM-DD")}
                                </div>
                              </td>
                              <td className=" text-end ">
                                <div className="gap-2  d-flex align-items-center justify-content-center">
                                  {moment(
                                    moment(
                                      moment.utc(data.valid_until).toDate()
                                    ).format("YYYY-MM-DD")
                                  ).isAfter(moment()) ? (
                                    <CopyToClipboard
                                      text={
                                        "https://cloudinstaller.app/" +
                                        APIConstants.download +
                                        data.type +
                                        "/" +
                                        data.token +
                                        "/"
                                      }
                                    >
                                      {
                                        <button
                                          onClick={() => {
                                            toast.success("Copied");
                                          }}
                                          className=" btn  bg-primary-light-blue font-family-opensans fw-bold  hover-btn  text-light"
                                        >
                                          Copy
                                        </button>
                                      }
                                    </CopyToClipboard>
                                  ) : (
                                    ""
                                  )}
                                  <button
                                    onClick={() => {
                                      toast((t) => (
                                        <span>
                                          Do you want to <b>delete? </b>
                                          <button
                                            className="btn  bg-danger font-family-opensans fw-bold  hover-btn  text-light"
                                            onClick={() => toast.dismiss(t.id)}
                                          >
                                            No
                                          </button>
                                          &nbsp;
                                          <button
                                            className="btn  bg-success font-family-opensans fw-bold  hover-btn  text-light"
                                            onClick={async () => {
                                              dispatchDeleteUsersApp(data.id);
                                              toast.dismiss(t.id);
                                            }}
                                          >
                                            Yes
                                          </button>
                                        </span>
                                      ));
                                    }}
                                    className="btn  bg-danger font-family-opensans fw-bold  hover-btn  text-light"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="" style={{ height: "70vh" }}>
                          <td colSpan={7} className="text-center ">
                            <div className="my-4 fs-5 text-color-primary">
                              No files here
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Row>
            </section>
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-center">
                <PaginationControl
                  style={{ margin: "2px" }}
                  page={page}
                  between={3}
                  total={uarray.length}
                  limit={10}
                  changePage={(page) => {
                    setPage(page);
                  }}
                  ellipsis={1}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="Support" title="Support">
            <section id="#support">
              <Row className="my-2">
                <div className="d-flex gap-3 align-items-center"></div>
              </Row>
              <Row>
                <Stack gap={2}>
                  <div className="mt-4">
                    <span className="fs-6 font-family-poppins  text-color-gray-dark ">
                      Want to change your password, &nbsp;
                    </span>
                    <a className="font-family-poppins" href="/reset">
                      click here
                    </a>
                  </div>

                  <div>
                    <span className="fs-6 font-family-poppins  text-color-gray-dark ">
                      For help and account inquiries, &nbsp;
                    </span>
                    <a href="contact-us/" className="font-family-poppins">
                      send us an email
                    </a>
                  </div>
                </Stack>
              </Row>
            </section>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default Dashboard;
