import React, { useState } from "react";
import { Container, Row, Stack, Form } from "react-bootstrap";
import logo from "../assets/images/cloudinstaller-logo2.png";
import Description from "../components/Description";
import AnimatedWave from "../components/AnimatedWave";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { UserLoginRequest } from "../api/AuthReducer";
import { AppLogger } from "../utils/AppLogger";
import toast, { Toaster } from "react-hot-toast";
import Spinner from "../components/Spinner";
import { useSelector, useDispatch } from "react-redux";
import AppRoutingPaths from "../utils/AppRoutingPaths";
import "../assets/styles/login.css";
import InputGroup from "react-bootstrap/InputGroup";
import { Eye, EyeSlashFill } from "react-bootstrap-icons";

const Login = () => {
  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    is_social: false,
  });
  const { loading } = useSelector((state) => state.AuthReducer);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  function handleFormSubmission(event) {
    event.preventDefault();

    dispatch(UserLoginRequest({ body: formData }))
      .then(unwrapResult)
      .then((res) => {
        AppLogger("Login Response ===", res);

        if (Array.isArray(res.data.data)) {
          const errorList = res.data.data;
          toast.error(
            errorList.map((error) => {
              return error;
            })
          );
        } else if (res.data.data.user) {
          navigateTo(AppRoutingPaths.dashboard);
        } else {
          toast.error("Something went Wrong! Please try again");
        }
      })
      .catch((err) => {
        AppLogger("Login Error Reponse ===", err.data);
        toast.error(err.message);
      });
  }

  return (
    <>
      <div className="animated-container">
        <Toaster />
        <Container fluid={true}>
          <Row>
            <div className="col-md-4 text-light height-vh">
              <div
                className="d-flex flex-column align-items-center justify-content-center p-4"
                gap={2}
                style={{ height: "inherit" }}
              >
                <a href="/">
                  <img
                    src={logo}
                    alt="logo_cloudinstaller"
                    className="img-fluid mx-auto"
                    width={235}
                  />
                </a>
                <Description
                  content="App Distribution made easy"
                  classNames="fs-6 text-center font-family-poppins"
                />
                <Stack direction="horizontal"></Stack>
              </div>
            </div>
            <div className="col-md-8  footer-background-color login-side height-vh2">
              <div
                className=" d-flex  flex-column align-items-center  justify-content-center"
                style={{ height: "inherit" }}
              >
                <div className="display-4 fw-medium text-center font-family-poppins my-2 displaytonone">
                  Welcome to <br></br> CloudInstaller.app
                </div>
                <Description
                  classNames="my-3"
                  content={
                    <span className="fw-medium">
                      New Here?{" "}
                      <a
                        className="fs-6 text-color-secondary-dark-blue font-family-poppins"
                        href="/register"
                      >
                        Create an Account
                      </a>
                    </span>
                  }
                />
                <div className="col-md-6 mx-auto login-width ">
                  <Form
                    onSubmit={handleFormSubmission}
                    className="border border-2 border-primary p-4  w-100 rounded text-start"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="lead fw-semibold mx-auto text-center">
                      Login
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="font-family-opensans fs-7">
                        Username
                      </Form.Label>
                      <Form.Control
                        className="form-focus rounded fs-7"
                        type="text"
                        placeholder="username"
                        maxLength={60}
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            username: event.target.value,
                          });
                        }}
                        required
                      />
                    </Form.Group>
                    <Form.Label className="font-family-opensans fs-7">
                      Password
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        placeholder="********"
                        aria-label="password"
                        maxLength={60}
                        aria-describedby="password-input"
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            password: event.target.value,
                          });
                        }}
                        required
                      />
                      <InputGroup.Text onClick={clickHandler}>
                        {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
                        {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}

                        {showPass ? <Eye /> : <EyeSlashFill />}
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Group
                      className="mb-3 text-center"
                      controlId="exampleForm.ControlInput1"
                    >
                      <input
                        type="submit"
                        name="submit"
                        className=" font-family-opensans  hover-btn bg-primary-light-blue btn mx-auto rounded  text-light"
                        value="Login"
                      />
                    </Form.Group>
                    <div className="w-100 text-end">
                      <a
                        href="/reset"
                        className="fs-7 text-color-secondary-dark-blue font-family-poppins"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
        <AnimatedWave />
        {loading === "pending" && <Spinner size={40} />}
      </div>
    </>
  );
};

export default Login;
