import AppRoutingPaths from "./AppRoutingPaths";
import AppImages from "./AppImages";

import personImage1 from "../assets/images/man1.jpeg";
import personImage2 from "../assets/images/man2.jpeg";
import personImage3 from "../assets/images/man3.jpeg";

export const CLOUDFLAREKEYS = {
  CLOUD_FLARE_SITE_KEY_1: "0x4AAAAAAAb_J8Ti41wyr5_S",
};
export const CLIENTTESTMONIALS = [
  {
    pic: personImage3,
    name: "Tom Townsend",
    testimonail:
      "It's the simplest way to share your apps with colleagues. Check them out.",
  },
  {
    pic: personImage1,
    name: "Steve Kneen",
    testimonail:
      "Thanks Cloud Installer for saving my work time. This is way better.",
  },
  {
    pic: personImage2,
    name: "Michael S.",
    testimonail:
      "We have initiated using Cloud Installer for an upcoming project, happy using it so far",
  },
];
export const PROCESSSTEPS = [
  {
    icon: "BsCloudUpload",
    title: "1. Upload",
    subtitle: "Initialize the process by uploading your App.",
  },
  {
    icon: "BsArrowUpRightSquare",
    title: "2. Submit",
    subtitle: "Get your App submitted after getting it tested.",
  },
  {
    icon: "BsShare",
    title: "3. Share",
    subtitle:
      "Get a link to be shared with your clients and friends or simply use it for your own device.",
  },
  {
    icon: "BsDownload",
    title: "4. Open",
    subtitle: "Access that link via iOS or Android device.",
  },
];

export const OTA_SHARE_FEATURES = [
  {
    icon: AppImages.androidAndIos,
    title: "Android and iOS Applications",
    description:
      "Cloud Installer works for both iOS as well as Android apps. It serves as alternatively to many applications like Testflight and Diawi. With time, it is turning out to be a go-to application for effortlessly testing mobile apps.Development, ad-hoc and in-house builds can be installed easily with a single tap.",
  },
  {
    icon: AppImages.dragAndDrop,
    title: "Drag and Drop Functionality",
    description:
      "As a developer, choose a file or simply drag and drop your ipa, zipped .app or apk file and get a link to be sent to your users, testers, bloggers, colleagues and friends.",
  },
  {
    icon: AppImages.oneTap,
    title: "One Tap Installation",
    description:
      "From now on, when you download an app in APK,IPA etc format, simply tap on its notification or select it from your Download folder. With one tap, the application will begin installation. ",
  },
  {
    icon: AppImages.privacy,
    title: "Privacy and Protection",
    description:
      "These generated links are private - no search and index. They can straightforwardly be password protected and firmly value security rules with provisioning profiles and UDIDs. ",
  },
  {
    icon: AppImages.troubleFree,
    title: "Trouble-free Tools for Device Compatibility",
    description:
      "We simply enable retrieving the UDID and cross-checking if the application is compatible with the used users device.",
  },
  {
    icon: AppImages.freeToGet,
    title: "Free to Get Going ",
    description:
      "Cloud Installer.app is FREE service! However, you may need to register to keep your build active for 60 days.",
  },
];

export const SHOWCASEDESCRIPTION = {
  mainDesc: "Distribute your iOS and Android Application",
  subDesc:
    "Install your Application over the air with our Best Platform for Beta App, iOS App and Android App Distribution.",
  mainDesc1: "DON’T WANT TO WASTE TIME GETTING YOUR APPS TO TESTERS?",
  subDesc1:
    "Are setups, reviews and processing times upsetting your performance",
  subDesc2:
    "Want to distribute your app at lightning speed? need a platform for swift sharing?,Dont worry Cloud Installer has got your back!",
};

export const SERVICESDESCRIPTION = {
  title: "Our Services",
  description:
    "Cloud-Installer is where we offer one of the top platforms with best ready services for Beta App, iOS App and Android App Distribution.Numerous enterprise-level and customized services, including research and consulting on application development, platform deployment, new feature development, technology support for your source code, delivery environment setup, technical support even on non-business days, and even the creation of new web or mobile applications, can be offered by our app developers and experts.",
};

export const NAVIGATIONLINKS = [
  {
    title: "Features",
    url: AppRoutingPaths.features,
  },
  {
    title: "FAQ",
    url: AppRoutingPaths.faq,
  },
  {
    title: "Login",
    url: AppRoutingPaths.login,
  },
  {
    title: "Register",
    url: AppRoutingPaths.register,
  },
  {
    title: "Terms And Conditions",
    url: AppRoutingPaths.terms,
  },
  {
    title: "Privacy Policies",
    url: AppRoutingPaths.privacyPolicy,
  },
];
export const NAVIGATIONLINKS2 = [
  {
    title: "Features",
    url: AppRoutingPaths.features,
  },
  {
    title: "FAQ",
    url: AppRoutingPaths.faq,
  },

  {
    title: "Terms And Conditions",
    url: AppRoutingPaths.terms,
  },
  {
    title: "Privacy Policies",
    url: AppRoutingPaths.privacyPolicy,
  },
];
export const NAVIGATION2 = [
  {
    title: "Login",
    url: AppRoutingPaths.login,
  },
  {
    title: "Register",
    url: AppRoutingPaths.register,
  },
];
export const contactUsLink = {
  title: "Contact Us",
  url: AppRoutingPaths.contactUs,
};

export const AllTerms = [
  {
    title:
      "The content of this site is for your general information and use only. It is subject to alter without prior notice.",
  },
  {
    title:
      "This website uses cookies to track browsing preferences. If you allow cookies to be utilized, your personal information may be stored by us for utilization by 3rd parties: Your Name and Your Email Address.",
  },
  {
    title:
      "Neither we nor any 3rd parties provide any guarantee as to the performance, precision, relevance, totality or appropriateness of the information and materials found on this site for any exacting purpose. You hereby acknowledge that such information and materials may contain errors or inaccuracies and we exclude the liability for such to the complete extent permissible by laws.",
  },
  {
    title:
      "Your use of any data, information and materials on this site is completely at your own risk, for which we shall not be accountable. It shall be your own liability to make sure that any products, services or details made available by this site meet your definite requirements.",
  },
  {
    title:
      "This site contains material which is owned by or licensed to us. This material comprises, however is not limited to, the content, design, layout, appearance, look and graphics of the site. Any reproduction of the website's material is forbidden other than in harmony with the copyright notice, which forms integral part of these terms and conditions.",
  },
  {
    title:
      "All trademarks reproduced in this site, which are not the property of, or licensed to us, are accepted on the site.",
  },
  {
    title:
      "Unauthorised usage of this site or portal may be a criminal offense and/or give rise to a claim for damages.",
  },
  {
    title:
      "This site may include links to other sites which are not controlled by us.  These links are presented for your handiness to provide you with further  details and information. You acknowledge that they are utilized at your own risks. They do not signify that we recommend these sites. We have no control  or responsibility for the nature, content and availability of those sites.",
  },
  {
    title:
      "Your usage of the site and any dispute arising out of your use of it is subject to the laws of Pakistan.",
  },
  {
    title:
      "You may only use the site for lawful purposes and in a manner constant with the nature and function of the website.",
  },
  {
    title:
      "These terms and conditions do not speak about to your use of any product or service described on our site unless otherwise agreed. You must refer to the individual warranty relevant to any exacting product or service.",
  },
  {
    title:
      "These terms and conditions may be modified or altered from time to time. Your continual use of our website following any such modifications or alterations or amendments will be deemed to be confirmation that you accept those amendments.",
  },
  {
    title:
      "You assure us from and against all claims, suits, demands, actions, liabilities, costs and expenses (including legal costs and expenses on a full indemnity basis) resultant from your utilization of the site.",
  },
  {
    title:
      "Every effort is made to keep the site up and running efficiently. However, we take no responsibility for, and will not be liable for, the site being temporarily unavailable due to technical problems or issues beyond our control.",
  },
];

export const AllTermsHeading = {
  title: "Terms and Conditions",
};
export const AllTermsDes = {
  title:
    " Welcome to our website. This website with URL address  is owned and operated by Cloud Installer. Should you continue to utilize this website, you are agreeing to comply with and be bound by the following terms and conditions of usage, which together with our privacy policy govern by our parent company OTA Share. If you are not in agreement with any of our terms and conditions please do not utilize the website.",
  des: " Your utilization of this website or portal is subject to the following terms and conditions:",
};

export const FAQs = [
  {
    question: "Is it really FREE?",
    description:
      "Cloud Installer is a FREE service at this moment. If you signup and use the service as logged in user then it will save build upto 8 days. Guest users will be able to use their build link upto 2 days.",
  },
  {
    question:
      "How many builds I can upload as a Guest or as a Registered user?",
    description:
      "There is a no limit. You can upload as many builds as you want. Only registered user can see their past uploaded builds with statistics on dashboard.",
  },
  {
    question: "How does this application work?",
    description:
      "Cloud Installer is a simple way to distribute iOS and Android applications. We sync with the required portals if needed so you don't require to waste time as well as efforts for multiple profile creation and their maintenance. The platform also allows installing the app in one single step. It is easier for both developers and testers.",
  },
  {
    question: "How is this better than other App installation services?",
    description:
      "We concentrate on providing you the finest Cloud Installer installation procedure. By syncing with the other portals, we got rid of the need for you to manually register on different devices and make or update profiles every time. We even monitor the status of all users so you can with no trouble see who has installed the app.    ",
  },
  {
    question: "Do I require providing you my Apple Developer login details?",
    description:
      "No. We don't require your Apple account credentials. You just need to upload your build to our platform and it will generate a short URL. You can provide that URL to your testers or clients.",
  },
  {
    question:
      "I have installed the app but it's showing greyed out icon on iphone?",
    description:
      "Main reason for disabled or greyed out icon is that your iOS device's UDID is not added to the provisioning profile which is being used to generate iOS build. Please contact your developer to add your device's UDID so you can install the build.",
  },
];

export const AllPoliciesHeading = {
  title: "Privacy policy",
  des: "This privacy policy sets out how Install Cloud Installer utilizes and protects any information that you provide when you use this site.\nWe are committed to make sure that your personal information is protected. Should we ask you to provide some details and information by which you can be recognized when using this site you can be certain that it will only be used in agreement with this privacy policy. If you do not wish to offer personal information to us then you do not have to do so, but it may affect your use of this site or the products and services provided on it.\nWe may modify this policy from time to time by updating this website page. You should verify this page frequently to make sure that you are at all times attentive of the present policy.",
};
export const AllPoliciesParaOne = {
  title: "What details we collect:",
  des: "We may ask you to offer the following information:",
};
export const AllPoliciesParaOneData = [
  "Name",
  "Email Address",
  "Other information if needed for promotions",
];
export const AllPoliciesParaRemaining = [
  {
    Title: "Data Ownership",
    Description:
      "All files and information you upload to the website remains your property. You can remove it at anytime through the site and it will be lastingly deleted from our servers. We collect this information to better understand your requirements, enabling us to enhance our products and services; for internal record keeping; for promotional emails about our products, particular offers or other information which we think you may find attention-grabbing; direct marketing; to contact you (by email or other contact details if provided) for market research purposes; and to modify the website according to your interests. We may use your personal information to send you marketing information about 3rd parties which we think you may find interesting. If you believe that any information we are holding on you is imprecise, out of date, partial, irrelevant or misleading, please email us as soon as possible at the email id in the contact us page. We will respond to any request within a realistic time and will attempt to promptly correct any details found to be erroneous so that the information is precise, up to date, absolute, relevant and not ambiguous.",
  },
  {
    Title: "Security",
    Description:
      "We are committed to make sure that the details you provide is secure. In order to put off unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial processes to safeguard and secure the details and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.",
  },
  {
    Title: "How we use cookies",
    Description:
      "We use traffic log cookies to identify which pages are being utilized. This assists us analyse information about web page traffic and get better our site by customizing it to your requirements. We use this information for statistical analysis purposes. Overall, cookies assist us offevr you with a enhanced website, by enabling us to track which pages you find useful and which you do not. You can choose to accept or decline cookies.",
  },
  {
    Title: "Links to other websites",
    Description:
      "Our site may contain links to other sites of interest. We do not have any control over those portals or sites. We are not accountable for the protection and privacy of any date or information which you offer while exploring such sites and such portals are not governed by our privacy policy. You should exercise caution and look at the privacy policy related to the site in query or any question.",
  },
  {
    Title: "Complaints",
    Description:
      "If you believe that we have breached the Pakistan Privacy Principles and wish to make a complaint about that breach then please email us as soon as possible at email id in the contact us page. We will fully try to reply to it in required time to resolve your complaints.",
  },
  {
    Title: "Amendments to this policy",
    Description:
      "This policy may be modified or amended from time to time. Your sustained use of our website following any such modifications will be deemed to be confirmation that you accept those modifications or amendments.",
  },
];
export const CompanyContact = {
  email: "info@siliconplex.com",
  phone: "+92-21-34820199",
};

export const FooterData = {
  AboutTitle: "About Us",
  AboutDes:
    "Cloud Installer is a platform for distributing your iOS and Android applications as well as installing it over the air. You can share that link to your clients, testers, friends or developers or utilize it for your own device. This is the best way to share, get quick and real feedback on your mobile application.",
  Links: "Links",
  ContactUs: "Contact Us",
};
