const AppImages = {
  androidAndIos: require(`../assets/images/android-ios.png`),
  dragAndDrop: require(`../assets/images/drag_n_drop.png`),
  oneTap: require(`../assets/images/one_tap.png`),
  privacy: require(`../assets/images/privacy.png`),
  troubleFree: require(`../assets/images/trouble-free.png`),
  freeToGet: require(`../assets/images/free.png`),

};

export default AppImages;
