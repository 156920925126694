import React from "react";
import Description from "./Description";
import logo from "../assets/images/otashare_small_logo.png";
import { Row, Stack } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useSelector, useDispatch } from "react-redux";
import {
  NAVIGATIONLINKS,
  contactUsLink,
  CompanyContact,
  FooterData,
  NAVIGATION2,
  NAVIGATIONLINKS2,
} from "../utils/AppConstants";

import { BsFillTelephoneFill } from "react-icons/bs";
import { ListGroup, Container } from "react-bootstrap";
import { CiMail } from "react-icons/ci";
const Footer = ({ extraClasses = "" }) => {
  const { user, token, loading } = useSelector((state) => state.AuthReducer);
  return (
    <>
      <section className={`footer-background-color p-2 ${extraClasses}`}>
        <Container>
          <Stack
            className="align-items-center justify-content-center"
          >
            <Nav className="">
                {NAVIGATIONLINKS2.map((navigation, index) => (
                  <Nav.Link
                    href={navigation.url}
                    key={index}
                    className="me-2 p-0 link-blue-hover text-color-gray-dark font-family-opensans"
                  >
                    {navigation.title}
                  </Nav.Link>
                ))}

                {!user &&
                  NAVIGATION2.map((navigation, index) => (
                    <Nav.Link
                      href={navigation.url}
                      key={index}
                      className="me-2 p-0 link-blue-hover text-color-gray-dark font-family-opensans"
                    >
                      {navigation.title}
                    </Nav.Link>
                  ))}

                <Nav.Link
                  href={contactUsLink.url}
                className="me-2 p-0 link-blue-hove text-color-gray-dark font-family-opensans"
                >
                  {contactUsLink.title}
                </Nav.Link>

            </Nav>
            <div
              className="w-100 d-flex justify-content-evenly align-items-center flex-column"
              style={{ minHeight: "150px" }}
            >
              <hr className="w-100"></hr>
              <div className="w-50 d-flex justify-content  d-flex justify-content-evenly align-items-center align-items-center ">
                <a href="http://www.facebook.com/siliconplex" role="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height={30}
                    width={30}
                  >
                    {" "}
                    <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
                  </svg>
                </a>
                <a href="http://www.twitter.com/siliconplex" role="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    height={30}
                    width={30}
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z" />
                  </svg>
                </a>
                <a
                  href="http://www.linkedin.com/company/siliconplex"
                  role="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    height={30}
                    width={30}
                  >
                    <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                  </svg>
                </a>
                <a href="mailto:info@siliconplex.com" role="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height={30}
                    width={30}
                  >
                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>
                </a>
              </div>
              <div>© Siliconplex, All Rights Reserved</div>
            </div>
            <div className="footerMobile pb-2">
              {"Powered by Siliconplex:    "}&nbsp;
              <a
                href="https://www.siliconplex.com/"
                target="_blank"
                style={{ zIndex: 99999, left: -1 }}
              >
                <img
                  src="https://pjps.pk/assets/Siliconplex_logo.png"
                  height="30"
                  alt="siliconplex_img"
                />
              </a>
            </div>

          </Stack>
        </Container>
      </section>
    </>
  );
};

export default Footer;
