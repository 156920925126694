import UserNavbar from "../components/UserNavbar";
import Container from "react-bootstrap/Container";
import Description from "../components/Description";
import ProcessBlock from "../components/ProcessBlock";
import CustomButton from "../components/CustomButton";
import mobileImage from "../assets/images/mobile.png";
import axios from "axios";

import {
  PROCESSSTEPS,
  SHOWCASEDESCRIPTION,
  SERVICESDESCRIPTION,
  CLIENTTESTMONIALS,
} from "../utils/AppConstants";

import { ProgressBar, Row, Stack } from "react-bootstrap";
import FileUpload from "../components/FileUpload";
import { Form } from "react-bootstrap";
import TestimonailCard from "../components/TestimonialCard";
import Footer from "../components/Footer";
import React, { useEffect, useRef, useState } from "react";
import FilePublicAccess from "../components/FilePublicAccess";
import APIConstants from "../utils/APIConstants";
import { AppLogger } from "../utils/AppLogger";
import { useSelector } from "react-redux";
import TurnstileWidget from "../components/TurnstileWidget";
import toast from "react-hot-toast";
import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

function Home({ NAVIGATIONLINKS }) {
  // const isValid = useValidationCookie('validation');
  var fileUploadProgress = 0;
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isProgress, setProgress] = useState(0);
  const [isFileUploadComplete, setFileUploadComplete] = useState(false);
  const [apiRes, setApiRes] = useState({});
  const [, setValidatedToken] = useState(false);

  const [formDisable, setFormDisable] = useState(true);
  const turnstileRef = useRef();
  const axiosRequest = axios.create({
    baseURL: APIConstants.BASE_URL,
    headers: {
      // Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  });

  const handleTurnstileExpire = () => {
    // turnstileRef.current?.reset()
    toast.error("Token Expired - Please verify again!");
    setFormDisable(true);
    setUploadedFile(null);
  };
  const handleFileProgress = (p) => {
    let fileUploadProgress = parseInt(p.progress * 100); // Parse progress as an integer
    fileUploadProgress = Math.min(Math.max(fileUploadProgress, 0), 100); // Clamp progress between 0 and 100
    setProgress(fileUploadProgress); // Update progress
  };

  const FileUploadRequest = createAsyncThunk(
    "AuthReducer/FileUploadRequest",
    async (payload, { rejectWithValue }) => {
      try {
        const res = await axiosRequest.post(
          APIConstants.fileUpload,
          payload.formData,
          {
            onUploadProgress: handleFileProgress,
            // signal: AbortSignal.timeout(10000 * 60), //three minutes and abort
          }
        );
        return res;
      } catch (error) {
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  async function btnClicked(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", uploadedFile["file"]);
    formData.append(
      "type",
      uploadedFile["fileExtension"] === "ipa" ? "ipa" : "apk"
    );

    if (user != null) {
      formData.append("user", user.id);
      formData.append("token", token);
    }

    AppLogger("Form Data === ", formData);

    try {
      const result = await dispatch(FileUploadRequest({ formData }));
      const res = unwrapResult(result);
      AppLogger("File Upload Response ", res);
      if (fileUploadProgress === 100) {
        setFileUploadComplete(true); // If progress is 100%, mark upload as complete
      }
      setFileUploadComplete(true); // If progress is 100%, mark upload as complete
      setApiRes(res.data);
      toast.success("File Uploaded Successfully");
    } catch (error) {
      setProgress(0);
      setFileUploadComplete(false);
      AppLogger("File Upload Error ", error);
      if (error.error) {
        setUploadedFile(null);
        setFormDisable(true);
        toast.error(error.error);
      } else {
        toast.error(error.message);
      }
    }
  }
  const handleNewFileUploadClick = (e) => {
    e.preventDefault();
    setFileUploadComplete(false);
    setProgress(0);
    setUploadedFile(null);
    setFormDisable(true);
  };

  useEffect(() => {
    if (isProgress === 100) {
      turnstileRef.current?.remove();
    }
    if (isProgress === 0) {
      turnstileRef.current?.render();
      // turnstileRef.current?.execute();
    }
  }, [isProgress]);

  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />

      <Container className="mt-6">
        <Row>
          <h1 className="mt-5 mb-3 fs-1 text-center font-family-poppins text-uppercase  lh-1 fw-semibold">
            {SHOWCASEDESCRIPTION.mainDesc}
          </h1>

          <h1
            className={
              "fs-5 app-dark-color text-center font-family-opensans fst-italic w-75  mx-auto"
            }
          >
            {SHOWCASEDESCRIPTION.subDesc}
          </h1>
        </Row>

        <Row className="mt-2 position-relative mb-3" id="uploadForm">
          <div className="line d-none d-md-block d-xl-block d-xxl-block d-sm-none "></div>
          {PROCESSSTEPS.map((step, index) => (
            <ProcessBlock
              key={index}
              childHeight="2em"
              childWidth="2em"
              childIconName={step.icon}
              description={{
                title: step.title,
                subtitle: step.subtitle,
              }}
            />
          ))}
        </Row>
        <Row>
          <Form>
            <Stack>
              {isFileUploadComplete ? (
                <FilePublicAccess
                  data={apiRes}
                  handleNewFileUploadClick={handleNewFileUploadClick}
                />
              ) : (
                <FileUpload
                  // isHide={hideFileUpload === true ? true : false}
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  formDisable={
                    isProgress > 0 && isProgress <= 100 ? true : false
                  }
                  // setError={setError}
                  // error={isError}
                />
              )}

              {isProgress > 0 && isProgress <= 100 && (
                <ProgressBar
                  animated
                  striped
                  now={isProgress}
                  label={`${isProgress}%`}
                  variant="success"
                  className={`fs-7 font-family-poppins fw-bold ${
                    isFileUploadComplete === true ? "d-none" : "d-block"
                  }`}
                />
              )}

              {/* {formDisable === true && uploadedFile === null && (
                  <div>
                    <button
                      disabled
                      id="fakeBtn"
                      className="customButton btn  bg-primary-light-blue font-family-opensans fw-bold mt-2 hover-btn  text-light"
                    >
                      Upload
                    </button>
                  </div>
                )} */}

              <div className="col-md-12 text-center">
                <div className="col-md-12 text-center d-flex flex-column">
                  {formDisable === false && (
                    <button
                      hidden={isFileUploadComplete}
                      disabled={!uploadedFile || isProgress > 0}
                      id="uploadBtn"
                      className="customButton btn  bg-primary-light-blue font-family-opensans fw-bold mt-2 hover-btn  text-light"
                      onClick={(e) => btnClicked(e)}
                    >
                      {!isProgress ? "Upload" : "Loading"}
                    </button>
                  )}

                  <TurnstileWidget
                    setValidatedFlareToken={setValidatedToken}
                    turnstileRef={turnstileRef}
                    handleExpiry={handleTurnstileExpire}
                    setFormDisable={setFormDisable}
                  />
                </div>
              </div>
            </Stack>
          </Form>
        </Row>
      </Container>
      <section className="banner-section banner-with-backgroundImage banner-with-gradient">
        <Container>
          <Row>
            <div className="col-md-6 text-light p-2 ">
              <Description
                content={SHOWCASEDESCRIPTION.mainDesc1}
                classNames={"mb-3 text-uppercase text-light fs-2 fw-bolder"}
              />
              <Description
                content={SHOWCASEDESCRIPTION.subDesc1}
                classNames={"mb-3  text-light fs-5 fw-medium"}
              />

              <Description
                content={SHOWCASEDESCRIPTION.subDesc2}
                classNames={"mb-3  text-light fs-5 fw-medium"}
              />

              <a
                href="#uploadForm "
                className="hover-btb-underline  customButton font-family-opensans fw-bold mx-auto mt-2 hover-btn  text-primary-light-blue  bg-light rounded-pill"
              >
                Try It Now
              </a>
            </div>
            <div className="col-md-6 descriptionMain  font-family-poppins fw-bold text-center d-none d-md-block d-xl-block d-xxl-block d-sm-none">
              <img className="img-fluid" src={mobileImage} alt="mobileImage" />
            </div>
          </Row>
        </Container>
      </section>
      <section className="banner-section py-0">
        <Container>
          <div className="text-center col-md-10 mx-auto ">
            <Description
              content={SERVICESDESCRIPTION.title}
              classNames="descriptionMain  font-family-poppins fs-4 mb-2"
            />
            <Description
              content={SERVICESDESCRIPTION.description}
              classNames={
                "descriptionSubtitle  font-family-opensans fs-6 mx-auto text-dark"
              }
            />
            <CustomButton
              redirectTo={"/contact-us"}
              text="Contact Us"
              classNames={
                " customButton font-family-opensans fw-bold mx-auto mt-2 hover-btn  bg-primary-light-blue  text-light  rounded-pill"
              }
            />
          </div>
        </Container>
      </section>

      <section className="banner-section banner-with-gradient mb-0 ">
        <Container className="w-100">
          <Row className="d-flex descriptionMain font-family-poppins fw-bold justify-content-center align-content-lg-center">
            <Description
              content={"What people say about us..."}
              classNames={
                "d-flex justify-content-center fw-bold align-content-lg-center font-family-poppins fs-2 text-uppercase text-light mb-4 mx-auto  "
              }
            />
          </Row>

          <Row>
            {CLIENTTESTMONIALS.map((testimonial, index) => (
              <TestimonailCard
                pic={testimonial.pic}
                key={index}
                testimonail={'"' + testimonial.testimonail + '"'}
                authorName={"-" + testimonial.name}
              />
            ))}
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default Home;
