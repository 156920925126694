import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileUpload } from "react-icons/md";
const FileUpload = ({
  isHide = false,
  uploadedFile,
  setUploadedFile,
  formDisable,
  // error,
  // setError,
}) => {
  const [isError, setError] = useState(null);
  const validateFileExtension = (file) => {
    const allowedExtensions = [".ipa", ".apk"];
    const fileExtension = file.name.split(".").pop();
    return allowedExtensions.includes("." + fileExtension.toLowerCase());
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        setError("Please choose a valid file.");
        setUploadedFile(null);
      } else if (!validateFileExtension(file)) {
        setError(
          "Only .ipa files for iOS or .apk files for Android are allowed."
        );
        setUploadedFile(null);
      } else {
        setError(null);
        setUploadedFile({
          fileExtension: file.name.split(".").pop(),
          filePath: file.path,
          file: file,
        });
      }
    },
    multiple: false, // Ensure only one file is accepted
    disabled: formDisable,
  });
  return (
    <div className={isHide ? "d-none" : ""}>
      <div
        className={`file-outer-container cursor-pointer file-container-hover `}
        style={{ minHeight: "30vh" }}
      >
        <div className="file-upload-container " {...getRootProps()}>
          <input {...getInputProps()} required />
          <div
            className="file-upload-content d-flex align-items-center justify-content-center"
            style={{ minHeight: "30vh" }}
          >
            <MdOutlineFileUpload className="icon-upload" />
            <div>
              {isError ? (
                <span className="error-message">{isError}</span>
              ) : uploadedFile ? (
                <span>
                  {" "}
                  {uploadedFile.file.name.length > 30
                    ? uploadedFile.file.name.slice(0, 29)
                    : uploadedFile.file.name}
                </span>
              ) : (
                <div>
                  <span>Choose a file</span> or Drag and Drop here
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
