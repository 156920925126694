import React from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const CustomButton = ({ text, redirectTo, classNames }) => {
  const navigationTo = useNavigate()

  const handleClick = (redirectTo) => {
    navigationTo(redirectTo);
  };

  const combinedClassNames = ` ${classNames || ""}`;

  return (
    <button className={combinedClassNames} onClick={() => handleClick(redirectTo)}>
      {text}
    </button>
  );
};

export default CustomButton;
