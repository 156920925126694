import { React, useState } from "react";
import { AppLogger } from "../utils/AppLogger";
import UserNavbar from "../components/UserNavbar";
import { Container } from "react-bootstrap";
import Description from "../components/Description";
import { Form } from "react-bootstrap";
import ResetModal from "../components/ResetModal";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useDispatch } from "react-redux";
import { ResetEmailRequest } from "../api/AuthReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import AppRoutingPaths from "../utils/AppRoutingPaths";
const Reset = ({ NAVIGATIONLINKS }) => {
  const [modalShow, setModalShow] = useState(false);
  const [resData, setResData] = useState(false);

  const navigationTo = useNavigate();

  const dispatch = useDispatch();
  const [contactUsObj, setContactUsObj] = useState({
    email: "",
  });
  function handleFormSubmission(e) {
    e.preventDefault();
    dispatch(ResetEmailRequest({ body: contactUsObj }))
      .then(unwrapResult)
      .then((response) => {
        if (response.data) {
          setResData(response.data.message);
        }
        setContactUsObj({
          email: "",
        });

        setModalShow(true);
      })
      .catch((error) => {
        AppLogger("Reset error  ==", error);
        setResData(error.message);
        setModalShow(true);
      });
  }

  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container>
        <div className="d-flex height-100 align-items-center  justify-content-center">
          <div className="bg-light shadow p-5 mb-5 bg-body rounded reset-container border  border-1 ">
            <Description
              content="Reset Password"
              classNames="descriptionMain font-family-poppins display-6 mb-2 text-center pb-2"
            />
            <Form className="" onSubmit={handleFormSubmission}>
              <div className="input-group mt-4">
                <span className="input-group-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="enter your email"
                  id="email"
                  name="email"
                  maxLength={60}
                  value={contactUsObj.email}
                  onChange={(e) =>
                    setContactUsObj({ ...contactUsObj, email: e.target.value })
                  }
                  required
                />
              </div>
              <div className=" flex-column justify-content-center mb-4">
                <input
                  type="submit"
                  name="submit"
                  className="w-100 customButton rounded-pill bg-primary-light-blue font-family-opensans fw-bold mx-auto mt-2 hover-btn  text-light"
                  value="Reset My Password"
                />
              </div>
            </Form>
          </div>
        </div>
      </Container>
      <ResetModal
        data={resData}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          navigationTo(AppRoutingPaths.home);
        }}
      />
    </>
  );
};

export default Reset;
