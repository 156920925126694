import React, { useState } from "react";
import { Container, Row, Stack } from "react-bootstrap";
import { Form } from "react-bootstrap";
import logo from "../assets/images/cloudinstaller-logo2.png";
import Description from "../components/Description";
import { UserSignUpRequest } from "../api/AuthReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AppLogger } from "../utils/AppLogger";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import AppRoutingPaths from "../utils/AppRoutingPaths";
import { PiSealWarningBold } from "react-icons/pi";
import TurnstileWidget from "../components/TurnstileWidget";
import toast from "react-hot-toast";
import { useRef } from "react";
import InputGroup from "react-bootstrap/InputGroup";

import AnimatedWave from "../components/AnimatedWave";
import "../assets/styles/login.css";
import { Eye, EyeSlashFill } from "react-bootstrap-icons";
export const Register = ({ NAVIGATIONLINKS }) => {
  const turnstileRef = useRef();
  const [formDisable, setFormDisable] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isValidatedToken, setValidatedToken] = useState(false);
  const [isError, setError] = useState(null);
  const [isLocalError, setLocalError] = useState(null);
  const [validate, setValidate] = useState(false);
  const [userBody, setUserBody] = useState({
    first_name: "s",
    last_name: "s",
    username: "",
    email: "",
    password: "",
  });
  var usernameRegex = /^[a-zA-Z]+([._]?[a-zA-Z0-9]+)*$/;
  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const clickHandlerConfirm = () => {
    setShowConfirm((prev) => !prev);
  };
  function handleFormSubmission(e) {
    e.preventDefault();
    const confirmPassword = e.target.confirmPassword.value;
    if (usernameRegex.test(userBody.username)) {
      setValidate(false);
      if (userBody.password === confirmPassword) {
        setLocalError(null);
        dispatchSignupAction();
      } else {
        setError(null);
        setLocalError("Password's don't match");
      }
    } else {
      setError(null);
      setLocalError(
        "Invalid username must contain alphanumeric value without spaces"
      );
    }
  }

  const handleTurnstileExpire = () => {
    // turnstileRef.current?.reset()
    toast.error("Token Expired - Please verify again!");
    setFormDisable(true);
  };

  function dispatchSignupAction() {
    dispatch(UserSignUpRequest({ body: userBody }))
      .then(unwrapResult)
      .then((response) => {
        if (response.data.token) {
          setError(null);
          setLocalError(null);
          navigate(AppRoutingPaths.home);
        }
      })
      .catch((error) => {
        AppLogger("user registratiion error ==", error);
        setError(error);
      });
  }

  return (
    <>
      <div className="animated-container">
        <Container fluid={true}>
          <Row>
            <div
              className="col-md-4 text-light height-vh d-flex justify-content-center align-items-center flex-column wregister"
              gap={2}
              style={{ height: "inherit" }}
            >
              <a href="/">
                <img
                  src={logo}
                  alt="cloudinstaller_image"
                  className="img-fluid mx-auto"
                  width={235}
                />
              </a>
              <Description
                content="App Distribution made easy"
                classNames="fs-6 text-center font-family-poppins"
              />
              <Stack direction="horizontal"></Stack>
            </div>

            <div className=" col-md-8  footer-background-color login-side height-vh2 d-flex justify-content-center align-items-center flex-column">
              <div className="row registerwidth">
                <div className="display-4 fw-medium text-center font-family-poppins my-2 displaytonone">
                  Welcome to <br></br> CloudInstaller.app
                </div>
                <Description
                  content="Sign Up. To see it in action."
                  classNames="fs-6 text-center font-family-opensans"
                />
                <div className="col-md-12 my-4" login-width>
                  <Form onSubmit={handleFormSubmission} className={validate}>
                    <Form.Group
                      className="mb-3"
                      controlId={false}
                      isValid={false}
                    >
                      <Form.Control
                        maxLength={50}
                        className="form-focus"
                        type="text"
                        placeholder="User Name"
                        name="username"
                        onChange={(e) =>
                          setUserBody({ ...userBody, username: e.target.value })
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        maxLength={60}
                        type="email"
                        className="form-focus "
                        placeholder="Email"
                        name="email"
                        onChange={(e) => {
                          setValidate(true);
                          setUserBody({ ...userBody, email: e.target.value });
                        }}
                        required
                      />
                    </Form.Group>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        placeholder="Password"
                        aria-label="password"
                        maxLength={60}
                        aria-describedby="password-input"
                        name="password"
                        onChange={(e) =>
                          setUserBody({ ...userBody, password: e.target.value })
                        }
                        required
                      />
                      <InputGroup.Text onClick={clickHandler}>
                        {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
                        {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
                        {showPass ? <Eye /> : <EyeSlashFill />}
                      </InputGroup.Text>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showConfirm ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        aria-label="password"
                        maxLength={60}
                        aria-describedby="password-input"
                        required
                      />
                      <InputGroup.Text onClick={clickHandlerConfirm}>
                        {/* You can use both. I ran into some width issues with FontAwesome but they can be fixed by playing around */}
                        {/* <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} /> */}
                        {showConfirm ? <Eye /> : <EyeSlashFill />}
                      </InputGroup.Text>
                    </InputGroup>

                    <div className="text-center">
                      {isError && (
                        <span className="text-center text-danger fs-6 my-2">
                          <PiSealWarningBold /> {isError.data + "\n"}
                        </span>
                      )}
                      {isLocalError && (
                        <span className=" text-center text-danger fs-6 my-2">
                          <PiSealWarningBold /> {isLocalError}
                        </span>
                      )}
                    </div>
                    <div className="d-flex flex-column justify-content-center mb-4 mt-2">
                      <div className="w-100 d-flex align-content-center justify-content-center flex-column">
                        <div className="mx-auto my-2">
                          <TurnstileWidget
                            setValidatedFlareToken={setValidatedToken}
                            turnstileRef={turnstileRef}
                            handleExpiry={handleTurnstileExpire}
                            setFormDisable={setFormDisable}
                          />
                        </div>
                        <input
                          disabled={formDisable === true}
                          type="submit"
                          name="submit"
                          className="mx-auto customButton btn bg-primary-light-blue font-family-opensans fw-bold  hover-btn  text-light"
                          value={" Register "}
                        />
                      </div>

                      <div className="row text-center mt-2">
                        <div className="fs-6 font-family-opensans">
                          Already have an Account?{" "}
                          <a
                            href="/login"
                            className="contact-us-main-colour link-color"
                          >
                            Login
                          </a>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
        <AnimatedWave />
      </div>
    </>
  );
};
