import React from "react";
import PropTypes from "prop-types";
import {
  BsCloudUpload,
  BsArrowUpRightSquare,
  BsShare,
  BsDownload,
} from "react-icons/bs";

import Description from "./Description";
const ProcessBlock = ({
  childHeight,
  childWidth,
  childIconName,
  description,
}) => {
  let ChildIconComponent;
  switch (childIconName) {
    case "BsCloudUpload":
      ChildIconComponent = BsCloudUpload;
      break;
    case "BsArrowUpRightSquare":
      ChildIconComponent = BsArrowUpRightSquare;
      break;
    case "BsShare":
      ChildIconComponent = BsShare;
      break;
    default:
      ChildIconComponent = BsDownload; // Default to BiCloudUpload if the icon name doesn't match
  }

  const childStyles = {
    height: childHeight,
    width: childWidth,
    color: "#FFF",
  };

  return (
    <div className="col-md-3 col-sm-6 mb-sm-2">
      <div className="background-gradient mx-auto rounded processBlock">
        {ChildIconComponent && <ChildIconComponent style={childStyles} />}
      </div>
      <div className="mb-md-4 mb-sm-1"></div>
      <Description
        content={description.title}
        classNames=" fs-3  font-family-poppins text-center text-uppercase fw-bold"
      />
      <Description
        content={description.subtitle}
        classNames="processBlockSubtitle text-center fw-medium font-family-opensans"
      />
    </div>
  );
};

ProcessBlock.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  childIconName: PropTypes.string,
  description: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }),
};

export default ProcessBlock;
