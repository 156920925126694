import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/otashare_small_logo.png";
import { AppLogger } from "../utils/AppLogger";
import { useSelector, useDispatch } from "react-redux";
import { LogoutRequest } from "../api/AuthReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import Spinner from "./Spinner";
import { CgProfile } from "react-icons/cg";
import "react-activity/dist/library.css";

export default function UserNavbar({ NAVIGATIONLINKS }) {
  const dispatch = useDispatch();
  const { user, token, loading } = useSelector((state) => state.AuthReducer);

  function logoutBtnClicked(e) {
    dispatch(LogoutRequest())
      .then(unwrapResult)
      .then((resp) => {
        AppLogger("logout response === ", resp);
      })
      .catch((error) => {});
  }

  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className="footer-background-color user-navbar"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              alt="cloud_installer_image"
              src={logo}
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end flex-grow-1"
          >
            <Nav className="text-uppercase poppins-font-family">
              {NAVIGATIONLINKS.slice(0, 4).map((navigation) => (
                <Nav.Link
                  as={NavLink}
                  to={navigation.url}
                  key={navigation.title}
                  // onClick={() => {
                  //   if (navigation.title === "Login") {
                  //     setModalShow(true);
                  //   }
                  // }}
                  hidden={
                    user &&
                    token &&
                    (navigation.title === "Login" ||
                      navigation.title === "Register")
                  }
                  className={`px-4 link-blue-hover ${
                    navigation.title === "Register" ? "app-primary-btn" : ""
                  }`}
                >
                  {navigation.title}
                </Nav.Link>
              ))}

              {user && token ? (
                <>
                  <Nav.Link
                    as={NavLink}
                    key="Logout"
                    onClick={() => logoutBtnClicked()}
                    className="px-4 link-blue-hover "
                  >
                    Logout
                  </Nav.Link>

                  <Nav.Link
                    as={NavLink}
                    to="/dashboard"
                    key={user.username}
                    className="px-4 link-blue-hover app-primary-btn"
                  >
                    <div className="d-flex align-items-center">
                      <CgProfile />
                      <span>
                        &nbsp;
                        {user.username}
                      </span>
                    </div>
                  </Nav.Link>
                </>
              ) : (
                <></>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {loading === "pending" && <Spinner size={40} />}
    </>
  );
}
