import React from "react";
import { Link } from "react-router-dom";
import APIConstants from "../utils/APIConstants";

const Description = ({ content, classNames, isLink, redirectTo, onclick }) => {
  const combinedClassNames = ` ${classNames || ""}`;

  if (isLink) {
    return (
      <Link
        to={redirectTo}
        className={combinedClassNames}
        onClick={
          window.location.href === APIConstants.LOCAL_URL + "reset" &&
          (() => onclick())
        }
      >
        {content}
      </Link>
    );
  }

  return <div className={combinedClassNames}>{content}</div>;
};

export default Description;
