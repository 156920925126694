import React from "react";
import UserNavbar from "../components/UserNavbar";
import { Container, Row } from "react-bootstrap";
import Description from "../components/Description";
import { Accordion } from "react-bootstrap";
import { FAQs } from "../utils/AppConstants";
import Footer from "../components/Footer";
const Faq = ({ NAVIGATIONLINKS }) => {
  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container className="mt-6 mb-5" style={{ minHeight: "58vh" }}>
        <Row>
          <Description
            content="FAQs"
            classNames="descriptionMain fs-1  font-family-poppings fw-bold text-capitalized mt-5"
          />
          <div className="my-2"></div>
        </Row>
        <Row>
          <Accordion defaultActiveKey="0" flush className="faqs-accordian">
            {FAQs.map((faq, index) => (
              <Accordion.Item
                eventKey={index}
                className="border border-black border-2 "
              >
                <Accordion.Header className=" ">
                  <Description
                    content={faq.question}
                    classNames="  descriptionMain font-family-poppins fs-4 lg-h"
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <Description
                    content={faq.description}
                    classNames=" text-color-gray-dark  descriptionMain font-family-poppins fs-6 lg-h"
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Faq;
