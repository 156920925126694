import React from "react";

const IconBox = ({ icon, className }) => {
  return (
    <div
      className={` ${className} ms-0 mx-auto shadow p-3 mb-5 bg-body rounded`}
    >
      <img src={icon} className="img-fluid" alt="process block icon" />
    </div>
  );
};

export default IconBox;
