import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import CustomButton from './CustomButton';
const NoMatch = () => {
  const location = useLocation();

  return (
    <Container className="text-center mt-5">
      <Row>
        <Col>
          <h1 className="display-4">404 - Not Found</h1>
          <p className="lead">
            The requested URL <code>{location.pathname}</code> was not found on this server.
          </p>
          <p>
            Return to <CustomButton text="Home" redirectTo={"/"} 
            classNames="customButton badge bg-primary-light-blue font-family-opensans fw-bold mx-auto mt-2 hover-btn  text-light"/>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default NoMatch;
