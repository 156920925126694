import React from "react";
import UserNavbar from "../components/UserNavbar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import { AllTerms, AllTermsDes, AllTermsHeading } from "../utils/AppConstants";
function Terms({ NAVIGATIONLINKS }) {
  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container className="mt-6 mb-10 terms-align-main">
        <div>
          <h1 className="descriptionMain fs-1  font-family-poppings fw-bold text-capitalized mt-5">
            {AllTermsHeading.title}
          </h1>
        </div>
        <div className="mb-5 mt-4">{AllTermsDes.title}</div>
        <div className="">
          <h4 className="mb-4 font-family-poppings">{AllTermsDes.des}</h4>
          <ul className="ulicon ">
            {AllTerms.map((term, index) => (
              <li className="liicon liicon-before font-family-poppings mt-4">
                {term.title}
              </li>
            ))}
          </ul>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Terms;
