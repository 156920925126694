import React from "react";
import { Modal } from "react-bootstrap";

const ContactSuccessModal = (props) => {
  const { onHide, show } = props;
  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      // {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0 w-100">
        <Modal.Title id="contained-modal-title-vcenter" className="w-100">
          <div className="d-flex font-family-poppins justify-content-center text-success">
            Your message has been sent.
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex font-family-poppins justify-content-center">
          We will contact you shortly
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactSuccessModal;
