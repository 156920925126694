import React from "react";
import UserNavbar from "../components/UserNavbar";
import { Container, Row } from "react-bootstrap";
import Description from "../components/Description";
import IconBox from "../components/IconBox";
import { OTA_SHARE_FEATURES } from "../utils/AppConstants";
import Footer from "../components/Footer";
import { MdPrivacyTip } from "react-icons/md";
// import android-ios from "../assets/images/android-ios.png";
// import drag-drop from "../assets/images/drag_n_drop"

const Features = ({ NAVIGATIONLINKS }) => {
  return (
    <>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      <Container className="mt-6">
        <Row>
          <Description
            content="Features"
            classNames="descriptionMain fs-1 text-center font-family-poppings fw-bold text-capitalized mt-5"
          />
          <div className="my-2"></div>
        </Row>
        <Row>
          {OTA_SHARE_FEATURES.map((feature, index) => (
            <div className="col-md-4   col-sm-6  d-flex  flex-column mb-5 ">
              <IconBox
                className=" p-4 text-align-right my-0"
                icon={feature.icon}
              />
              <Description
                content={feature.title}
                classNames="descriptionMain  ms-0   font-family-poppins fs-5 mb-2 lg-h"
              />
              <Description
                content={feature.description}
                classNames={
                  "descriptionSubtitle text-justify  font-family-opensans fs-6 mx-auto text-dark text-justified"
                }
              />
            </div>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Features;
