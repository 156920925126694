import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ChangePassModal = (props) => {
  const { onHide, show, data } = props;

  const [isError, setError] = useState(null);

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setError(null);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0 w-100 d-flex">
        <div className="d-flex w-100 fs-4 text-succcess font-family-poppins justify-content-center">
          {data === "success" ? (
            <div className="text-success">Success</div>
          ) : (
            <div className="text-danger">Error</div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex font-family-poppins justify-content-center">
          {data === "" ? (
            <div className="text-success">Password Changed Successfully</div>
          ) : (
            <div className="">{data}</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassModal;
