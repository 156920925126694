import React from "react";
import { Modal } from "react-bootstrap";

const ResetModal = (props) => {
  const { onHide, show, data } = props;

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      // {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <div className="w-100 ">
          {data === "User with this email not found" ? (
            <div className="d-flex fs-4 font-family-poppins justify-content-center text-danger">
              No User
            </div>
          ) : (
            <div className="d-flex fs-4 font-family-poppins justify-content-center text-success">
              Link Sent
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex font-family-poppins justify-content-center">
          {data}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResetModal;
