import React from "react";
import UserNavbar from "../components/UserNavbar";
import Footer from "../components/Footer";
import { Container, Stack } from "react-bootstrap";
import ReactQrCode from "@devmehq/react-qr-code";
import { useLocation } from "react-router-dom";
import APIConstants from "../utils/APIConstants";
const AppDownload = ({ NAVIGATIONLINKS }) => {
  const location = useLocation();
  const data = location.pathname.split("/");
  const device =
    navigator.userAgent.match(/Android/i) !== null
      ? "Android"
      : navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null
      ? "iPhone"
      : "Browser";

  return (
    <div className="position-relative" style={{ minHeight: "100vh" }}>
      <UserNavbar NAVIGATIONLINKS={NAVIGATIONLINKS} />
      {/* <div className="mt-6"></div> */}
      <Container className="position-absolute top-50 start-50 translate-middle">
        <div className="d-flex flex-column border-1 border-black p-md-5 p-sm-0">
          <div className="file-outer-container margintopminus">
            <div className="file-upload-container text-danger  ">
              <Stack className="align-items-center" gap={4}>
                <div>
                  {data[1] === "apk" && device !== "Android" ? (
                    <div>
                      You are <strong>NOT</strong> using an{" "}
                      <strong> Android </strong>
                      device. Links & icons WILL NOT work.
                    </div>
                  ) : data[1] === "ipa" && device !== "iPhone" ? (
                    <div>
                      You are <strong>NOT</strong> using an{" "}
                      <strong> IOS </strong>
                      device. Links & icons WILL NOT work.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-decoration-none text-center fw-bold font-family-opensans fs-4 ">
                  {(data[1] === "ipa" && device === "iPhone") ||
                  (data[1] === "apk" && device === "Android") ? (
                    <a
                      className="cursor-pointer btn bg-primary-light-blue text-light"
                      download
                      href={
                        data[1] === "apk"
                          ? APIConstants.DOWNLOAD_BASE_URL +
                            APIConstants.download +
                            data[1] +
                            "/" +
                            data[2] +
                            "/"
                          : "itms-services://?action=download-manifest&url=" +
                            APIConstants.DOWNLOAD_BASE_URL +
                            APIConstants.download +
                            data[1] +
                            "/" +
                            data[2] +
                            "/"
                      }
                    >
                      Click Here To Install
                    </a>
                  ) : (
                    <button
                      disabled
                      className="btn bg-primary-light-blue text-light"
                    >
                      Click Here To Install
                    </button>
                  )}
                </div>
                <ReactQrCode
                  value={
                    data[1] === "apk"
                      ? APIConstants.DOWNLOAD_BASE_URL +
                        APIConstants.download +
                        data[1] +
                        "/" +
                        data[2] +
                        "/"
                      : "itms-services://?action=download-manifest&url=" +
                        APIConstants.DOWNLOAD_BASE_URL +
                        APIConstants.download +
                        data[1] +
                        "/" +
                        data[2] +
                        "/"
                  }
                />
              </Stack>

              {/* <div className="text-decoration-none processBlockSubtitle text-center fw-bold font-family-opensans fs-6 d-flex align-items-center justify-content-center draganddrop">
              <div className="w-50 d-flex align-items-center justify-content-center h-100">
                <div
                  className="d-flex align-items-center justify-content-center widthvw"
                  style={{ height: "90%", width: "50%" }}
                >
                  <ReactQrCode
                    value={
                      data[1] === "apk"
                        ? APIConstants.DOWNLOAD_BASE_URL +
                          APIConstants.download +
                          data[1] +
                          "/" +
                          data[2] +
                          "/"
                        : "itms-services://?action=download-manifest&url=" +
                          APIConstants.DOWNLOAD_BASE_URL +
                          APIConstants.download +
                          data[1] +
                          "/" +
                          data[2] +
                          "/"
                    }
                  />
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {"Powered by Siliconplex:    "}&nbsp;
          <a
            href="https://www.siliconplex.com/"
            target="_blank"
            style={{ zIndex: 99999, left: -1 }}
          >
            <img
              src="https://pjps.pk/assets/Siliconplex_logo.png"
              height="30"
              alt="siliconplex_img"
            />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default AppDownload;
